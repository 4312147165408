import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Button,
    Paper,
    TextField,
    Switch as Toggle,
    FormControlLabel,
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@material-ui/core';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slider from '@material-ui/core/Slider';
import { Editor } from '@tinymce/tinymce-react';
import { BlockPicker } from 'react-color';

import styles from './Add.module.css';

const NoCardAddPage = () => {
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [metaImage, setMetaImage] = useState("");
    const [content, setContent] = useState("");
    const [minNumberOfRandomWords, setMinNumberOfRandomWords] = useState(1);
    const [percentageOfWholeWords, setPercentageOfWholeWords] = useState(0);
    const [randomWords, setRandomWords] = useState([]);
    const [randomWordAmt, setRandomWordAmt] = useState(1);
    const [wholeWords, setWholeWords] = useState([]);
    const [highlightWord, setHighlightWord] = useState(0)
    const [highlightWordColor, setHighlightWordColor] = useState("#e30c07");
    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState("");
    const [slug, setSlug] = useState("");
    const [spacesBetweenWords, setSpacesBetweenWords] = useState(false);
    const [description, setDescription] = useState("");
    const [active, setActive] = useState(false);
    const [popular, setPopular] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("error");
    const [alertMessage, setAlertMessage] = useState("Unknown error occured.")
    const [disableBtn, setDisableBtn] = useState(false);
    const [disableWordColor, setDisableWordColor] = useState(true);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");
    const history = useHistory();

    const handleSubmit = () => {
        setDisableBtn(true);
        axios.post('/api/pages', {
            template: "noCard",
            name,
            title,
            type,
            category,
            icon,
            description,
            active,
            metaImage,
            slug,
            content,
            popular,
            spacesBetweenWords,
            minNumberOfRandomWords,
            percentageOfWholeWords,
            randomWords,
            wholeWords,
            highlightWord,
            highlightWordColor,
        })
        .then((res) => {
            setAlertSeverity("success");
            setAlertMessage("Page successfully created.");
            setAlert(true);
            setTimeout(() => {
                history.push('/');
            }, 5000);
        })
        .catch((err) => {
            setAlertSeverity("error");
            setAlertMessage(err.message);
            setAlert(true);
            setDisableBtn(false);
        });
    };

    const handleHighlightWord = (wordPos) => {
        setHighlightWord(wordPos);
        if (wordPos === 0) return setDisableWordColor(true);
        setDisableWordColor(false);
    }

    const handleRandomWords = (words, i) => {
        const newRandomWords = [...randomWords];
        newRandomWords[i] = words.replace(/\r?\n|\r/g, ',').split(',');
        setRandomWords(newRandomWords);
    }

    const handleAddRandomWords = () => {
        const newRandomWords = [...randomWords];
        newRandomWords[randomWordAmt] = [];
        setRandomWords(newRandomWords);
        setRandomWordAmt(randomWordAmt + 1);
    }

    const handleRemoveWords = () => {
        const newRandomWords = [...randomWords];
        newRandomWords.pop();
        setRandomWords(newRandomWords);
        setRandomWordAmt(randomWordAmt - 1);
    }
    useEffect(() => {
        axios.get('/api/categories/')
            .then((res) => {
                setCategories(res.data)
            })
    },[]);

    useEffect(() => {
        if(name.length > 0 && category.length > 0) setSlug(`/${category.replaceAll(' ', '-').toLowerCase()}/${name.replaceAll(' ', '-').toLowerCase()}/`);
    }, [category, name]);

    return (
    <>
        <Container className={styles.pages} maxWidth="md">
            <h2>Add a New Page</h2>
            <Paper className={styles.form}>
                <Typography className={styles.sectionHeader} variant="h5">
                    Page Settings
                </Typography>
                <TextField
                    id="outlined-basic"
                    placeholder="Name"
                    variant="outlined"
                    label="Name"
                    className={styles.formField}
                    onChange={(ev) => setName(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Farm"
                    variant="outlined"
                    label="Type"
                    className={styles.formField}
                    onChange={(ev) => setType(ev.target.value)}
                    required
                />
                <FormControl className={styles.formField} variant="outlined">
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                        labelId="category-label"
                        value={category}
                        variant="outlined"
                        label="Category"
                        onChange={(ev) => setCategory(ev.target.value)}
                    >
                        <MenuItem value="">
                            <em>Select a category ...</em>
                        </MenuItem>
                        {categories.map((cat) => <MenuItem key={cat._id} value={cat.name}>{cat.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField
                    id="outlined-basic"
                    placeholder="Slug"
                    variant="outlined"
                    label="Slug"
                    value={slug}
                    className={styles.formField}
                    onChange={(ev) => setSlug(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Title"
                    variant="outlined"
                    label="Title"
                    className={styles.formField}
                    onChange={(ev) => setTitle(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="FaWizard"
                    variant="outlined"
                    label="Icon"
                    className={styles.formField}
                    onChange={(ev) => setIcon(ev.target.value)}
                    required
                />
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={styles.sectionHeader} variant="h5">
                            Word Settings
                        </Typography>
                    </AccordionSummary>
                    <div className={styles.oddSettings}>
                        <div className={styles.oddSetting}>
                            <Slider
                                value={Number(percentageOfWholeWords)}
                                valueLabelDisplay="auto"
                                valueLabelFormat={percentageOfWholeWords + "%"}
                                defaultValue={10}
                                min={0}
                                max={100}
                                onChange={(ev, value) => setPercentageOfWholeWords(value)}
                            />
                            <span>Percentage of Whole Words</span>
                        </div>
                        <div className={styles.oddSetting}>
                            <Slider
                                value={Number(minNumberOfRandomWords)}
                                valueLabelDisplay="auto"
                                min={1}
                                max={randomWordAmt}
                                marks={true}
                                onChange={(ev, value) => setMinNumberOfRandomWords(value)}
                            />
                            <span>Minimum # of Random Words</span>
                        </div>
                        <div className={styles.oddSetting}>
                            <Slider
                                value={Number(highlightWord)}
                                valueLabelDisplay="auto"
                                min={0}
                                max={randomWordAmt}
                                marks={true}
                                onChange={(ev, value) => handleHighlightWord(value)}
                            />
                            <span className={styles.fieldLabel}>Highlight Random Word</span>
                            <div className={disableWordColor ? styles.hidden : ''}>
                                <BlockPicker
                                    color={highlightWordColor}
                                    onChange={(color) => setHighlightWordColor(color.hex)}
                                />
                            </div>
                        </div>
                    </div>
                    <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setSpacesBetweenWords(!spacesBetweenWords)}
                            checked={spacesBetweenWords}
                            required
                        />}
                    label="Spaces Between Words"
                    />
                    <div className={styles.formFields}>
                        <TextField
                                label="Whole Words"
                                multiline
                                rows={12}
                                className={`${percentageOfWholeWords === 0 ? styles.hidden : styles.formField}`}
                                placeholder="Word1, Word2, Word3, etc ..."
                                variant="outlined"
                                value={wholeWords}
                                onChange={(ev) => setWholeWords(ev.target.value.replace(/\r?\n|\r/g, ',').split(','))}
                        />
                        {[...Array(randomWordAmt)].map((e, i) =>
                            <TextField
                                key={"randomWords_" + i}
                                label="Random Words"
                                multiline
                                value={randomWords[i]}
                                rows={12}
                                className={styles.formField}
                                placeholder="Word1, Word2, Word3, etc ..."
                                variant="outlined"
                                onChange={(ev) => handleRandomWords(ev.target.value, i)}
                            />
                        )}
                        <div className={styles.btnRow}>
                            <Button color="primary" onClick={() => handleAddRandomWords()}>+ Add Random Words</Button>
                            <Button color="secondary" className={randomWordAmt > 1 ? '' : styles.hidden} onClick={() => handleRemoveWords()}>- Remove Random Words</Button>
                        </div>
                    </div>
                </Accordion>
                <Accordion className={styles.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography className={styles.sectionHeader} variant="h5">
                                Content Settings
                            </Typography>
                        </AccordionSummary>
                        <div className={styles.content}>
                        <Editor
                            initialValue=""
                            apiKey="1wu0aulm4cmddrw8unuwxz81g4e6bs01vp4ryjjwk6gw5goq"
                            init={{
                            height: 500,
                            menubar: false,
                            plugins: ['table','link', 'emoticons', 'wordcount'],
                            toolbar:
                            // eslint-disable-next-line
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | link removeformat emoticons | wordcount help | \
                                table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
                            }}
                            onEditorChange={(content) => setContent(content)}
                        />
                    </div>
                    <div className={styles.formFields}>
                        <TextField
                            id="outlined-basic"
                            placeholder="s3-image-url"
                            variant="outlined"
                            label="Meta Image URL"
                            className={styles.formField}
                            onChange={(ev) => setMetaImage(ev.target.value)}
                            required
                        />
                        <TextField
                            id="outlined-basic"
                            placeholder="Description"
                            variant="outlined"
                            label="Meta Description"
                            multiline
                            rows={4}
                            className={styles.formField}
                            onChange={(ev) => setDescription(ev.target.value)}
                            required
                        />
                    </div>
                </Accordion>
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setActive(!active)}
                            checked={active}
                            required
                        />}
                    label="Active"
                />
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setPopular(!popular)}
                            checked={popular}
                            required
                        />}
                    label="Popular"
                />
            </Paper>
            <div className={styles.btn}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!name.length || !title.length || !icon.length || !description.length || disableBtn }
                    onClick={() => handleSubmit()}
                >
                    Save Page
                </Button>
            </div>
        </Container>
        <Snackbar open={alert} autoHideDuration={5000} onClose={() => setAlert(false)}>
            <Alert severity={alertSeverity}>
                {alertMessage}
            </Alert>
        </Snackbar>
    </>
    );
};

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export default NoCardAddPage;