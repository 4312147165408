import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { Button, TextField, Switch as Toggle, FormControlLabel, Container } from '@material-ui/core';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useParams } from 'react-router-dom';
import styles from './Edit.module.css';

const EditCategory = () => {
    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState("");
    const [description, setDescription] = useState("");
    const [active, setActive] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("error");
    const [alertMessage, setAlertMessage] = useState("Unknown error occured.")
    const { id } = useParams();

    const handleSubmit = () => {
        axios.put(`/api/categories/${id}`, {
            name,
            title,
            icon,
            description,
            active,
        })
        .then((res) => {
            setAlertSeverity("success");
            setAlertMessage("Category successfully updated.");
            setAlert(true);
        })
        .catch((err) => {
            setAlertSeverity("error");
            setAlertMessage(err.message);
            setAlert(true);
        });
    };

    useEffect(() => {
        axios.get(`/api/categories/${id}`)
            .then((res) => {

                setName(res.data.name);
                setTitle(res.data.title);
                setDescription(res.data.description);
                setIcon(res.data.icon);
                setActive(Boolean(res.data.active));
            })
    },[id]);
    return (
    <>
        <Container className={styles.pages} maxWidth="md">
            <h2>Edit Category</h2>
            <Paper className={styles.form}>
                <TextField
                    id="outlined-basic"
                    placeholder="Name"
                    variant="outlined"
                    label="Name"
                    value={name}
                    className={styles.formField}
                    onChange={(ev) => setName(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Title"
                    variant="outlined"
                    label="Title"
                    value={title}
                    className={styles.formField}
                    onChange={(ev) => setTitle(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="FaWizard"
                    variant="outlined"
                    value={icon}
                    label="Icon"
                    className={styles.formField}
                    onChange={(ev) => setIcon(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Description"
                    variant="outlined"
                    value={description}
                    label="Meta Description"
                    multiline
                    rows={4}
                    className={styles.formField}
                    onChange={(ev) => setDescription(ev.target.value)}
                    required
                />
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setActive(!active)}
                            checked={active}
                            required
                        />}
                    label="Active"
                />

            </Paper>
            <div className={styles.btn}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                >
                    Update Category
                </Button>
            </div>
        </Container>
        <Snackbar open={alert} autoHideDuration={5000} onClose={() => setAlert(false)}>
            <Alert severity={alertSeverity}>
                {alertMessage}
            </Alert>
        </Snackbar>
    </>
    );
};

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export default EditCategory;