import React, { useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WebIcon from '@material-ui/icons/Web';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import GitHubIcon from '@material-ui/icons/GitHub';
import styles from './MenuDrawer.module.css';

const MenuDrawer = ({ open }) => {
    useEffect(() => {}, [open]);

    return (
        <Drawer
            open={open}
            anchor="right"
            className={styles.drawer}
            variant="persistent"
        >
            <List className={styles.menu}>
                <ListItem button component="a" href="https://generator.ninja" target="_blank">
                    <ListItemIcon>
                        <WebIcon />
                    </ListItemIcon>
                    <ListItemText primary="Website" />
                </ListItem>
                <ListItem button component="a" href="https://analytics.google.com/analytics/web/#/report-home/a161825120w226856213p214378904" target="_blank">
                    <ListItemIcon>
                        <InsertChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Analytics" />
                </ListItem>
                <ListItem button component="a" href="https://github.com/LCSmith87/generator-ninja" target="_blank">
                    <ListItemIcon>
                        <GitHubIcon />
                    </ListItemIcon>
                    <ListItemText primary="Repo" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default MenuDrawer;