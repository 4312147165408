import { AppBar, Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MenuDrawer from '../../components/MenuDrawer';
import { Container } from '@material-ui/core';
import styles from './Dashboard.module.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Switch, Route } from 'react-router-dom';
import axios from 'axios';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NoCardAddPage from '../Page/NoCard/Add';
import NoCardEditPage from '../Page/NoCard/Edit';
import RandomAttrAddPage from '../Page/RandomAttr/Add';
import RandomAttrEditPage from '../Page/RandomAttr/Edit';
import AddCategory from '../Category/Add';
import EditCategory from '../Category/Edit';
import FixedAttrAddPage from '../Page/FixedAttr/Add';
import FixedAttrEditPage from '../Page/FixedAttr/Edit';

const Dashboard = ({ userId }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLogout = () => {
        window.location.reload();
    }

    axios.defaults.headers.common['api_token'] = userId;
    return(
        <div className={styles.dashboard}>
                <AppBar position="static">
                    <MenuDrawer open={menuOpen} />
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={styles.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                        <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" className={styles.title}>
                            <Link to="/">Dashboard</Link>
                        </Typography>
                        <Button onClick={() => handleLogout()} color="inherit">Logout</Button>
                    </Toolbar>
                </AppBar>
            <Switch>
                <Route exact path="/">
                    <DashboardMain />
                </Route>
                <Route path="/page/nocard/add">
                    <NoCardAddPage />
                </Route>
                <Route path="/page/nocard/edit/:id">
                    <NoCardEditPage />
                </Route>
                <Route path="/page/randomattr/add">
                    <RandomAttrAddPage />
                </Route>
                <Route path="/page/randomattr/edit/:id">
                    <RandomAttrEditPage />
                </Route>
                <Route path="/category/add">
                    <AddCategory />
                </Route>
                <Route path="/category/edit/:id">
                    <EditCategory />
                </Route>
                <Route path="/page/fixedattr/add">
                    <FixedAttrAddPage />
                </Route>
                <Route path="/page/fixedattr/edit/:id">
                    <FixedAttrEditPage />
                </Route>
            </Switch>
        </div>
    );
};

const DashboardMain = () => {
    const [catCount, setCatCount] = useState(0);
    const [categories, setCategories] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    const handlePageDelete = (id, template) => {
        axios.delete(`/api/pages/${id}?template=${template}`)
            .then(() => {
                const newPages = pages.filter((p) => p._id !== id);
                setPages(newPages);
                setPageCount(newPages.length);
            });
    }

    const handleCategoryDelete = (id) => {
        axios.delete(`/api/categories/${id}`)
            .then(() => {
                const newCategories = categories.filter((c) => c._id !== id);
                setCategories(newCategories);
                setCatCount(newCategories.length);
            });
    }

    useEffect(() => {
        axios.get('/api/categories/count')
            .then((res) => {
                setCatCount(res.data.count);
            })
            .catch((err) => {
                setCatCount(0);
            });
        axios.get('/api/pages/count')
            .then((res) => {
                setPageCount(res.data.count);
            })
            .catch((err) => {
                setPageCount(0);
            });
         axios.get('/api/categories/')
            .then((res) => {
                setCategories(res.data);
            });
         axios.get('/api/pages/')
            .then((res) => {
                setPages(res.data);
            });
    },[]);

    return (
        <Container className={styles.pages} maxWidth="md">
            <div className={styles.pagesData}>
                <Paper className={styles.dataCard}>
                    <span className={styles.dataCardTitle}>Pages</span>
                    <span className={styles.dataCardNumber}>{pageCount}</span>
                </Paper>
                <Paper className={styles.dataCard}>
                    <span className={styles.dataCardTitle}>Categories</span>
                    <span className={styles.dataCardNumber}>{catCount}</span>
                </Paper>
            </div>
            <div className={styles.topBtns}>
                <Link to="/category/add"><Button variant="contained">Add Category</Button></Link>
                <Link to="/page/nocard/add"><Button variant="contained">Add No Card Page</Button></Link>
                <Link to="/page/randomattr/add"><Button variant="contained">Add Random Attr Page</Button></Link>
                <Link to="/page/fixedattr/add"><Button variant="contained">Add Fixed Attr Page</Button></Link>
            </div>
            <h3>Categories</h3>
            <TableContainer component={Paper}>
                <Table className={styles.table} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Title</TableCell>
                        <TableCell align="right">Status</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category) =>
                            <NewCategoryTableRow
                                key={category._id}
                                id={category._id}
                                name={category.name}
                                title={category.title}
                                status={category.active ? "Published" : "Draft"}
                                del={handleCategoryDelete}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <h3>Pages</h3>
            <TableContainer component={Paper}>
                <Table className={styles.table} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Template</TableCell>
                        <TableCell align="right">Category</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Popular</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {pages.map((page) =>
                            <NewPageTableRow
                                key={page._id}
                                id={page._id}
                                name={page.name}
                                template={page.template}
                                status={page.active ? "Published" : "Draft"}
                                category={page.category}
                                popular={page.popular.toString()}
                                del={handlePageDelete}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

const NewCategoryTableRow = ({ id, name, icon, title, status, del }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (ev) => {
        setAnchorEl(ev.currentTarget);
        setMenuOpen(!menuOpen);
    }

    const handleCatDelete = (ev, id) => {
        setAnchorEl(ev.currentTarget);
        setMenuOpen(!menuOpen);
        del(id);
    }

    return (
        <TableRow>
            <TableCell component="th" scope="row">{name}</TableCell>
            <TableCell align="right">{title}</TableCell>
            <TableCell align="right">{status}</TableCell>
            <TableCell align="right"><MoreVertIcon onClick={(ev) => handleMenuClick(ev)} className={styles.menuBtn} /></TableCell>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={(ev) => handleMenuClick(ev)}
            >
                <MenuItem className={styles.menuItem} onClick={() => handleMenuClick}><Link to={`/category/edit/${id}`}>Edit Category</Link></MenuItem>
                <MenuItem className={styles.menuItem} onClick={(ev) => handleCatDelete(ev, id)}><Link to="#">Delete Category</Link></MenuItem>
            </Menu>
        </TableRow>
    )
}

const NewPageTableRow = ({ id, name, category, status, template, del, popular }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (ev) => {
        setAnchorEl(ev.currentTarget);
        setMenuOpen(!menuOpen);
    }

    const handlePageDelete = (ev, id, template) => {
        setAnchorEl(ev.currentTarget);
        setMenuOpen(!menuOpen);
        del(id, template);
    }

    return (
        <TableRow>
            <TableCell component="th" scope="row">{name}</TableCell>
            <TableCell align="right">{template}</TableCell>
            <TableCell align="right">{category}</TableCell>
            <TableCell align="right">{status}</TableCell>
            <TableCell align="right">{popular}</TableCell>
            <TableCell align="right"><MoreVertIcon onClick={(ev) => handleMenuClick(ev)} className={styles.menuBtn} /></TableCell>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={(ev) => handleMenuClick(ev)}
            >
                <MenuItem className={styles.menuItem} onClick={() => handleMenuClick}><Link to={`/page/${template.toLowerCase()}/edit/${id}`}>Edit Page</Link></MenuItem>
                <MenuItem className={styles.menuItem} onClick={(ev) => handlePageDelete(ev, id, template)}><Link to="#">Delete Page</Link></MenuItem>
            </Menu>
        </TableRow>
    )
}

export default Dashboard;

