import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { IfFirebaseAuthed, IfFirebaseUnAuthed } from '@react-firebase/auth';
import Login from '../../pages/Login';
import Dashboard from '../../pages/Dashboard';



const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/">
        <IfFirebaseAuthed>
        {({ user }) => {
          return <Dashboard userId={user.uid} />
        }}
        </IfFirebaseAuthed>
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default App;

const PrivateRoute = ({ children, ...rest }) => {
  return(
    <>
      <IfFirebaseAuthed>
          {() => {
            return (
              <Route  {...rest} render={() => (
                children
              )} />
            )
          }}
        </IfFirebaseAuthed>
        <IfFirebaseUnAuthed>
          {() => {
            return (
              <Redirect
              to={{
                pathname: "/login",
              }}
            />
            )
          }}
        </IfFirebaseUnAuthed>
    </>
  );
};