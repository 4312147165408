import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from './logo-medium.png';
import { Container, TextField, Button } from '@material-ui/core';
import styles from './Login.module.css';
import firebase from "firebase/app";
import 'firebase/auth';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const handleLogin = () => {
    setError("");
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push('/');
      })
      .catch(() => {
        setError("Invalid login or password");
      });
  };

  const handleKeyPress = (ev) => {
    if(ev.key === 'Enter') handleLogin();
  }
    return (
      <div className={styles.app}>
        <Container maxWidth="sm">
          <img className={styles.logo} src={logo} alt="Generator Ninja Logo" />
          <h1>Login</h1>
          <div className={styles.loginForm}>
              <TextField
                id="outlined-basic"
                label="E-mail"
                variant="outlined"
                type="email"
                className={styles.email}
                onChange={(ev) => setEmail(ev.target.value)}
                required
              />
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type="password"
                className={styles.password}
                onChange={(ev) => setPassword(ev.target.value)}
                onKeyPress={(ev) => handleKeyPress(ev)}
                required
              />
              <span className={styles.error}>{error}</span>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleLogin()}
              >
                Login
              </Button>
          </div>
        </Container>
      </div>
    );
};

export default Login;
