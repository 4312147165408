import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './utilities/reportWebVitals';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FirebaseAuthProvider } from '@react-firebase/auth';

const config = {
  apiKey: "AIzaSyC00jWJYa1XL1B_RJHzR_mQZQIhnmwJ2QU",
  projectId: "generator-ninja.firebaseapp.com",
  authDomain: "generator-ninja",
  databaseURL: "generator-ninja.appspot.com",
};
ReactDOM.render(
    <FirebaseAuthProvider firebase={firebase} {...config}>
      <App />
    </FirebaseAuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
