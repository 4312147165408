import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Button,
    Paper,
    TextField,
    Switch as Toggle,
    FormControlLabel,
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@material-ui/core';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Editor } from '@tinymce/tinymce-react';
import { useDropzone } from 'react-dropzone';


import styles from './Add.module.css';

const FixedAttrAddPage = () => {
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [metaImage, setMetaImage] = useState("");
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState("");
    const [slug, setSlug] = useState("");
    const [description, setDescription] = useState("");
    const [hideCard, setHideCard] = useState(false);
    const [active, setActive] = useState(false);
    const [popular, setPopular] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("error");
    const [alertMessage, setAlertMessage] = useState("Unknown error occured.")
    const [disableBtn, setDisableBtn] = useState(false);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");
    const [includeImage, setIncludeImage] = useState(false);
    const [file, setFile] = useState(null);
    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const history = useHistory();

    const handleSubmit = () => {
        let form = new FormData();
        form.append('template', 'fixedAttr');
        form.append('name', name);
        form.append('title', title);
        form.append('type', type);
        form.append('category', category);
        form.append('slug', slug);
        form.append('icon', icon);
        form.append('description', description);
        form.append('active', active);
        form.append('metaImage', metaImage);
        form.append('hideCard', hideCard);
        form.append('popular', popular);
        form.append('content', content);
        form.append('includeImage', includeImage);
        form.append('csv', file);
        axios({
            method: 'POST',
            url: '/api/pages/fixedattr/upload',
            headers: {
                'content-type': 'multipart/form-data',
            },
            data: form,
        })
        .then((res) => {
            setAlertSeverity("success");
            setAlertMessage("Page successfully created.");
            setAlert(true);
            setTimeout(() => {
                history.push('/');
            }, 5000);
            setDisableBtn(false);
        })
        .catch((err) => {
            setAlertSeverity("error");
            setAlertMessage(err.message);
            setAlert(true);
            setDisableBtn(false);
        });
    };

    useEffect(() => {
        axios.get('/api/categories/')
            .then((res) => {
                setCategories(res.data)
            })
    },[]);

    useEffect(() => {
        if(name.length > 0 && category.length > 0) setSlug(`/${category.replaceAll(' ', '-').toLowerCase()}/${name.replaceAll(' ', '-').toLowerCase()}/`);
    }, [category, name]);

    return (
    <>
        <Container className={styles.pages} maxWidth="md">
            <h2>Add a New Page</h2>
            <Paper className={styles.form}>
                <Typography className={styles.sectionHeader} variant="h5">
                    Page Settings
                </Typography>
                <TextField
                    id="outlined-basic"
                    placeholder="Name"
                    variant="outlined"
                    label="Name"
                    className={styles.formField}
                    onChange={(ev) => setName(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Farm"
                    variant="outlined"
                    label="Type"
                    className={styles.formField}
                    onChange={(ev) => setType(ev.target.value)}
                    required
                />
                <FormControl className={styles.formField} variant="outlined">
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                        labelId="category-label"
                        value={category}
                        variant="outlined"
                        label="Category"
                        onChange={(ev) => setCategory(ev.target.value)}
                    >
                        <MenuItem value="">
                            <em>Select a category ...</em>
                        </MenuItem>
                        {categories.map((cat) => <MenuItem key={cat._id} value={cat.name}>{cat.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField
                    id="outlined-basic"
                    placeholder="Slug"
                    variant="outlined"
                    label="Slug"
                    value={slug}
                    className={styles.formField}
                    onChange={(ev) => setSlug(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Title"
                    variant="outlined"
                    label="Title"
                    className={styles.formField}
                    onChange={(ev) => setTitle(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="FaWizard"
                    variant="outlined"
                    label="Icon"
                    className={styles.formField}
                    onChange={(ev) => setIcon(ev.target.value)}
                    required
                />
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setIncludeImage(!includeImage)}
                            checked={includeImage}
                            required
                        />}
                    label="Include Image?"
                />
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setHideCard(!hideCard)}
                            checked={hideCard}
                            required
                        />}
                    label="Hide Card?"
                />
                <div className={styles.drop} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                        <p>Drop a CSV here...</p> :
                        <p>Drag 'n' drop a CSV here, or click to select file</p>
                    }
                </div>
                <span className={styles.uploadedFile}>Uploaded File: <span className={styles.uploadedFileName}>{file && file.name}</span></span>
                <Accordion className={styles.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography className={styles.sectionHeader} variant="h5">
                                Content Settings
                            </Typography>
                        </AccordionSummary>
                        <div className={styles.content}>
                        <Editor
                            initialValue=""
                            apiKey="1wu0aulm4cmddrw8unuwxz81g4e6bs01vp4ryjjwk6gw5goq"
                            init={{
                            height: 500,
                            menubar: false,
                            plugins: ['table','link', 'emoticons', 'wordcount'],
                            toolbar:
                            // eslint-disable-next-line
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | link removeformat emoticons | wordcount help | \
                                table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
                            }}
                            onEditorChange={(content) => setContent(content)}
                        />
                    </div>
                    <div className={styles.formFields}>
                        <TextField
                            id="outlined-basic"
                            placeholder="s3-image-url"
                            variant="outlined"
                            label="Meta Image URL"
                            className={styles.formField}
                            onChange={(ev) => setMetaImage(ev.target.value)}
                            required
                        />
                        <TextField
                            id="outlined-basic"
                            placeholder="Description"
                            variant="outlined"
                            label="Meta Description"
                            multiline
                            rows={4}
                            className={styles.formField}
                            onChange={(ev) => setDescription(ev.target.value)}
                            required
                        />
                    </div>
                </Accordion>
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setActive(!active)}
                            checked={active}
                            required
                        />}
                    label="Active"
                />
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setPopular(!popular)}
                            checked={popular}
                            required
                        />}
                    label="Popular"
                />
            </Paper>
            <div className={styles.btn}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!name.length || !title.length || !icon.length || !description.length || disableBtn }
                    onClick={() => handleSubmit()}
                >
                    Save Page
                </Button>
            </div>
        </Container>
        <Snackbar open={alert} autoHideDuration={5000} onClose={() => setAlert(false)}>
            <Alert severity={alertSeverity}>
                {alertMessage}
            </Alert>
        </Snackbar>
    </>
    );
};

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export default FixedAttrAddPage;