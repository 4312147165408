import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Button,
    Paper,
    TextField,
    Switch as Toggle,
    FormControlLabel,
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@material-ui/core';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slider from '@material-ui/core/Slider';
import { Editor } from '@tinymce/tinymce-react';
import { BlockPicker } from 'react-color';
import styles from './Add.module.css';


const RandomAttrAddPage = () => {
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [metaImage, setMetaImage] = useState("");
    const [content, setContent] = useState("");
    const [minNumberOfRandomWords, setMinNumberOfRandomWords] = useState(1);
    const [percentageOfWholeWords, setPercentageOfWholeWords] = useState(0);
    const [randomWords, setRandomWords] = useState([]);
    const [randomWordAmt, setRandomWordAmt] = useState(1);
    const [attributes, setAttributes] = useState([{ name: "Attribute Name", values: ["attr1","attr2","attr3"]}]);
    const [attributeAmt, setAttributeAmt] = useState(1);
    const [wholeWords, setWholeWords] = useState([]);
    const [highlightWord, setHighlightWord] = useState(0)
    const [highlightWordColor, setHighlightWordColor] = useState("#e30c07");
    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState("");
    const [description, setDescription] = useState("");
    const [spacesBetweenWords, setSpacesBetweenWords] = useState(false);
    const [active, setActive] = useState(false);
    const [popular, setPopular] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("error");
    const [alertMessage, setAlertMessage] = useState("Unknown error occured.")
    const [disableBtn, setDisableBtn] = useState(false);
    const [disableWordColor, setDisableWordColor] = useState(true);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");
    const { id } = useParams();

    const handleSubmit = () => {
        setDisableBtn(true);
        axios.put(`/api/pages/${id}?template=randomAttr`, {
            name,
            title,
            type,
            category,
            icon,
            description,
            active,
            metaImage,
            content,
            popular,
            minNumberOfRandomWords,
            percentageOfWholeWords,
            spacesBetweenWords,
            randomWords,
            attributes,
            wholeWords,
            highlightWord,
            highlightWordColor,
        })
        .then((res) => {
            setAlertSeverity("success");
            setAlertMessage("Page succesfully updated.");
            setAlert(true);
            setDisableBtn(false);
        })
        .catch((err) => {
            setAlertSeverity("error");
            setAlertMessage(err.message);
            setAlert(true);
            setDisableBtn(false);
        });
    };

    const handleHighlightWord = (wordPos) => {
        setHighlightWord(wordPos);
        if (wordPos === 0) return setDisableWordColor(true);
        setDisableWordColor(false);
    }

    const handleWholeWords = (words) => {
        let newWords = [...wholeWords];
        newWords = words.replace(/\r?\n|\r/g, ',').split(',');
        setWholeWords(newWords);
    }

    const handleRandomWords = (words, i) => {
        let newRandomWords = [...randomWords];
        newRandomWords[i] = words.replace(/\r?\n|\r/g, ',').split(',');
        setRandomWords(newRandomWords);
    }

    const handleAddRandomWords = () => {
        let newRandomWords = [...randomWords];
        newRandomWords[randomWordAmt] = [];
        setRandomWords(newRandomWords);
        setRandomWordAmt(randomWordAmt + 1);
    }

    const handleRemoveWords = () => {
        let newRandomWords = [...randomWords];
        newRandomWords.pop();
        setRandomWords(newRandomWords);
        setRandomWordAmt(randomWordAmt - 1);
    }

    const handleAttributes = (words, i) => {
        let newAttr = [...attributes];
        newAttr[i] = { ...newAttr[i], values: words.replace(/\r?\n|\r/g, ',').split(',')};
        setAttributes(newAttr);
    }

    const handleAttributeName = (name, i) => {
        let newAttr = [...attributes];
        newAttr[i] = { ...newAttr[i], name};
        setAttributes(newAttr);
    }

    const handleAddAttributes = () => {
        let newAttr = [...attributes];
        newAttr[attributeAmt] = { name: "Attribute Name", values: ["attr1","attr2","attr3"]};
        setAttributes(newAttr);
        setAttributeAmt(attributeAmt + 1);
    }

    const handleRemoveAttributes = () => {
        let newAttr = [...attributes];
        newAttr.pop();
        setAttributes(newAttr);
        setAttributeAmt(attributeAmt - 1);
    }

    useEffect(() => {
        axios.get('/api/categories/')
            .then((res) => {
                setCategories(res.data)
            })
        axios.get(`/api/pages/${id}?template=randomAttr`)
        .then((res) => {
            setName(res.data.name);
            setTitle(res.data.title);
            setDescription(res.data.description);
            setCategory(res.data.category);
            setIcon(res.data.icon);
            setActive(Boolean(res.data.active));
            setType(res.data.type);
            setMetaImage(res.data.metaImage);
            setContent(res.data.content);
            setSpacesBetweenWords(res.data.spacesBetweenWords);
            setMinNumberOfRandomWords(res.data.minNumberOfRandomWords);
            setPercentageOfWholeWords(res.data.percentageOfWholeWords);
            setRandomWords(res.data.randomWords);
            setRandomWordAmt(res.data.randomWords.length);
            setPopular(res.data.popular);
            setAttributes(res.data.attributes);
            setAttributeAmt(res.data.attributes.length);
            setWholeWords(res.data.wholeWords);
            setHighlightWord(res.data.highlightWord);
            setHighlightWordColor(res.data.highlightWordColor);
        })
        // eslint-disable-next-line
    },[]);

    return (
    <>
        <Container className={styles.pages} maxWidth="md">
            <h2>Edit Page</h2>
            <Paper className={styles.form}>
                <Typography className={styles.sectionHeader} variant="h5">
                    Page Settings
                </Typography>
                <TextField
                    id="outlined-basic"
                    placeholder="Name"
                    variant="outlined"
                    label="Name"
                    className={styles.formField}
                    onChange={(ev) => setName(ev.target.value)}
                    value={name}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Farm"
                    variant="outlined"
                    label="Type"
                    value={type}
                    className={styles.formField}
                    onChange={(ev) => setType(ev.target.value)}
                    required
                />
                <FormControl className={styles.formField} variant="outlined">
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                        labelId="category-label"
                        value={category}
                        variant="outlined"
                        label="Category"
                        onChange={(ev) => setCategory(ev.target.value)}
                    >
                        <MenuItem value="">
                            <em>Select a category ...</em>
                        </MenuItem>
                        {categories.map((cat) => <MenuItem key={cat._id} value={cat.name}>{cat.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField
                    id="outlined-basic"
                    placeholder="Title"
                    variant="outlined"
                    value={title}
                    label="Title"
                    className={styles.formField}
                    onChange={(ev) => setTitle(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="FaWizard"
                    variant="outlined"
                    value={icon}
                    label="Icon"
                    className={styles.formField}
                    onChange={(ev) => setIcon(ev.target.value)}
                    required
                />
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={styles.sectionHeader} variant="h5">
                            Word Settings
                        </Typography>
                    </AccordionSummary>
                    <div className={styles.oddSettings}>
                        <div className={styles.oddSetting}>
                            <Slider
                                value={Number(percentageOfWholeWords)}
                                valueLabelDisplay="auto"
                                valueLabelFormat={percentageOfWholeWords + "%"}
                                defaultValue={10}
                                min={0}
                                max={100}
                                onChange={(ev, value) => setPercentageOfWholeWords(value)}
                            />
                            <span>Percentage of Whole Words</span>
                        </div>
                        <div className={styles.oddSetting}>
                            <Slider
                                value={Number(minNumberOfRandomWords)}
                                valueLabelDisplay="auto"
                                min={1}
                                max={randomWordAmt}
                                marks={true}
                                onChange={(ev, value) => setMinNumberOfRandomWords(value)}
                            />
                            <span>Minimum # of Random Words</span>
                        </div>
                        <div className={styles.oddSetting}>
                            <Slider
                                value={Number(highlightWord)}
                                valueLabelDisplay="auto"
                                min={0}
                                max={randomWordAmt}
                                marks={true}
                                onChange={(ev, value) => handleHighlightWord(value)}
                            />
                            <span className={styles.fieldLabel}>Highlight Random Word</span>
                            <div className={disableWordColor ? styles.hidden : ''}>
                                <BlockPicker
                                    color={highlightWordColor}
                                    onChange={(color) => setHighlightWordColor(color.hex)}
                                />
                            </div>
                        </div>
                    </div>
                    <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setSpacesBetweenWords(!spacesBetweenWords)}
                            checked={spacesBetweenWords}
                            required
                        />}
                    label="Spaces Between Words"
                    />
                    <div className={styles.formFields}>
                        <TextField
                                label="Whole Words"
                                multiline
                                rows={12}
                                className={`${percentageOfWholeWords === 0 ? styles.hidden : styles.formField}`}
                                placeholder="Word1, Word2, Word3, etc ..."
                                variant="outlined"
                                value={wholeWords}
                                onChange={(ev) => handleWholeWords(ev.target.value)}
                        />
                        {[...Array(randomWordAmt)].map((e, i) =>
                            <div className={styles.attributeFields} key={"random_" + i}>
                                <span className={styles.attributeTitle}>Random Words #{i + 1}</span>
                                <TextField
                                    key={"randomWords_" + i}
                                    label="Random Words"
                                    multiline
                                    value={randomWords[i] || ''}
                                    rows={12}
                                    className={styles.formField}
                                    placeholder="Word1, Word2, Word3, etc ..."
                                    variant="outlined"
                                    onChange={(ev) => handleRandomWords(ev.target.value, i)}
                                />
                            </div>
                        )}
                        <div className={styles.btnRow}>
                            <Button color="primary" onClick={() => handleAddRandomWords()}>+ Add Random Words</Button>
                            <Button color="secondary" className={randomWordAmt > 1 ? '' : styles.hidden} onClick={() => handleRemoveWords()}>- Remove Random Words</Button>
                        </div>
                    </div>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={styles.sectionHeader} variant="h5">
                            Attribute Settings
                        </Typography>
                    </AccordionSummary>
                    <div className={styles.formFields}>
                        {[...Array(attributeAmt)].map((e, i) =>
                            <div className={styles.attributeFields} key={"attribute_" + i}>
                                <span className={styles.attributeTitle}>Attribute #{i + 1}</span>
                                <TextField
                                    key={"attributes_name_" + i}
                                    label="Attribute Name"
                                    variant="outlined"
                                    className={styles.formField}
                                    placeholder="Attribute Name"
                                    value={attributes[i].name || ''}
                                    onChange={(ev) => handleAttributeName(ev.target.value, i)}
                                />
                                <TextField
                                    key={"attributes_words_" + i}
                                    label="Attributes"
                                    multiline
                                    rows={12}
                                    className={styles.formField}
                                    placeholder="Attr1, Attr2, Attr3, etc ..."
                                    variant="outlined"
                                    value={attributes[i].values || ''}
                                    onChange={(ev) => handleAttributes(ev.target.value, i)}
                                />
                            </div>
                        )}
                        <div className={styles.btnRow}>
                            <Button color="primary" onClick={() => handleAddAttributes()}>+ Add Attribute</Button>
                            <Button color="secondary" onClick={() => handleRemoveAttributes()} className={attributeAmt > 1 ? '' : styles.hidden}>- Remove Attribute</Button>
                        </div>
                    </div>
                </Accordion>
                <Accordion className={styles.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography className={styles.sectionHeader} variant="h5">
                                Content Settings
                            </Typography>
                        </AccordionSummary>
                        <div className={styles.content}>
                        <Editor
                            value={content}
                            apiKey="1wu0aulm4cmddrw8unuwxz81g4e6bs01vp4ryjjwk6gw5goq"
                            init={{
                            height: 500,
                            menubar: false,
                            plugins: ['table','link', 'emoticons', 'wordcount'],
                            toolbar:
                            // eslint-disable-next-line
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | link removeformat emoticons | wordcount help | \
                                table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
                            }}
                            onEditorChange={(content) => setContent(content)}
                        />
                    </div>
                    <div className={styles.formFields}>
                        <TextField
                            id="outlined-basic"
                            placeholder="s3-image-url"
                            variant="outlined"
                            value={metaImage}
                            label="Meta Image URL"
                            className={styles.formField}
                            onChange={(ev) => setMetaImage(ev.target.value)}
                            required
                        />
                        <TextField
                            id="outlined-basic"
                            placeholder="Description"
                            variant="outlined"
                            value={description}
                            label="Meta Description"
                            multiline
                            rows={4}
                            className={styles.formField}
                            onChange={(ev) => setDescription(ev.target.value)}
                            required
                        />
                    </div>
                </Accordion>
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setActive(!active)}
                            checked={active}
                            required
                        />}
                    label="Active"
                />
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setPopular(!popular)}
                            checked={popular}
                            required
                        />}
                    label="Popular"
                />
            </Paper>
            <div className={styles.btn}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!name.length || !title.length || !icon.length || !description.length || disableBtn }
                    onClick={() => handleSubmit()}
                >
                    Save Page
                </Button>
            </div>
        </Container>
        <Snackbar open={alert} autoHideDuration={5000} onClose={() => setAlert(false)}>
            <Alert severity={alertSeverity}>
                {alertMessage}
            </Alert>
        </Snackbar>
    </>
    );
};

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export default RandomAttrAddPage;