import React, { useState, useEffect } from 'react';
import { Button, TextField, Switch as Toggle, FormControlLabel, Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import styles from './Add.module.css';

const AddCategory = () => {
    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState("");
    const [description, setDescription] = useState("");
    const [active, setActive] = useState(false);
    const [alert, setAlert] = useState(false);
    const [slug, setSlug] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("error");
    const [alertMessage, setAlertMessage] = useState("Unknown error occured.")
    const [disableBtn, setDisableBtn] = useState(false);
    const history = useHistory();

    const handleSubmit = () => {
        setDisableBtn(true);
        axios.post('/api/categories', {
            name,
            title,
            icon,
            description,
            active,
            slug,
        })
        .then((res) => {
            setAlertSeverity("success");
            setAlertMessage("Category successfully created.");
            setAlert(true);
            setTimeout(() => {
                history.push('/');
            }, 5000);
        })
        .catch((err) => {
            setAlertSeverity("error");
            setAlertMessage(err.message);
            setAlert(true);
            setDisableBtn(false);
        });
    };

    useEffect(() => {
        if(name.length > 0) setSlug(`/${name.replaceAll(' ', '-').toLowerCase()}/`);
    }, [name]);

    return (
    <>
        <Container className={styles.pages} maxWidth="md">
            <h2>Add a New Category</h2>
            <Paper className={styles.form}>
                <TextField
                    id="outlined-basic"
                    placeholder="Name"
                    variant="outlined"
                    label="Name"
                    className={styles.formField}
                    onChange={(ev) => setName(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Slug"
                    variant="outlined"
                    label="Slug"
                    value={slug}
                    className={styles.formField}
                    onChange={(ev) => setSlug(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Title"
                    variant="outlined"
                    label="Title"
                    className={styles.formField}
                    onChange={(ev) => setTitle(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="FaWizard"
                    variant="outlined"
                    label="Icon"
                    className={styles.formField}
                    onChange={(ev) => setIcon(ev.target.value)}
                    required
                />
                <TextField
                    id="outlined-basic"
                    placeholder="Description"
                    variant="outlined"
                    label="Meta Description"
                    multiline
                    rows={4}
                    className={styles.formField}
                    onChange={(ev) => setDescription(ev.target.value)}
                    required
                />
                <FormControlLabel
                    control={
                        <Toggle
                            color="primary"
                            className={styles.formField}
                            onChange={(ev) => setActive(!active)}
                            checked={active}
                            required
                        />}
                    label="Active"
                />

            </Paper>
            <div className={styles.btn}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!name.length || !title.length || !icon.length || !description.length || disableBtn }
                    onClick={() => handleSubmit()}
                >
                    Save Category
                </Button>
            </div>
        </Container>
        <Snackbar open={alert} autoHideDuration={5000} onClose={() => setAlert(false)}>
            <Alert severity={alertSeverity}>
                {alertMessage}
            </Alert>
        </Snackbar>
    </>
    );
};

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export default AddCategory;